/*-----------------------------------------------
|   Notice
-----------------------------------------------*/
.notice {
    position: fixed;
    left: 0;
    bottom: 0;
    margin: 0 !important;
    z-index: -1;
    border: 0;
    width: 100%;
    max-width: 100%;
    box-shadow: $box-shadow-lg;
    border-radius: 0;
    &.show {
        z-index: $zindex-modal-backdrop;
    }
    .btn-close {
        margin-top: 0.75rem;
    }
}
