/* -------------------------------------------------------------------------- */
/*                                    Flex                                    */
/* -------------------------------------------------------------------------- */

.flex-center {
    align-items: center;
    justify-content: center;
}
.flex-between-center {
    justify-content: space-between;
    align-items: center;
}
.flex-end-center {
    justify-content: flex-end;
    align-items: center;
}
.flex-between-end {
    align-items: flex-end;
    justify-content: space-between;
}

.flex-1 {
    flex: 1;
}
@include media-breakpoint-up(lg) {
    .flex-lg-basis-0 {
        flex-basis: 0;
    }
}
