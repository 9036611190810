.coming-soon {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 2;

    &-text {
        position: absolute;
        color: black;
        font-weight: bold;
        z-index: 3;
    }
}
