/*-----------------------------------------------
|   Table
-----------------------------------------------*/
.table {
    tfoot > tr > th:first-child,
    thead > tr > th:first-child,
    tr th:first-child,
    tr td:first-child {
        padding-left: $card-spacer-x;
    }
    tfoot > tr > th:last-child,
    thead > tr > th:last-child,
    tr th:last-child,
    tr td:last-child {
        padding-right: $card-spacer-x;
    }
    .dropdown,
    .dropstart,
    .dropend {
        position: static;
    }
}
.white-space-nowrap {
    width: 1px;
    white-space: nowrap;
}
th {
    font-weight: $font-weight-semi-bold;
}
td .dropdown-toggle {
    &:after,
    &:before {
        display: none;
    }
}
.table-dashboard {
    overflow: hidden;
    th {
        border-bottom-width: 1px !important;
    }
}

// .table-responsive {
//   @extend .scrollbar;
// }
