/* -------------------------------------------------------------------------- */
/*                                Falcon Button                               */
/* -------------------------------------------------------------------------- */
@mixin falcon-button-variant($color) {
    color: var(--#{$prefix}btn-falcon-#{$color}-color);
    background-color: var(--#{$prefix}btn-falcon-background);
    // @if $enable-gradients {
    //   background-image: var(--#{$prefix}gradient);
    // }
    border-color: var(--#{$prefix}btn-falcon-background);
    box-shadow: var(--#{$prefix}btn-falcon-box-shadow);

    @include hover-focus {
        color: var(--#{$prefix}btn-falcon-#{$color}-hover-color);
        background-color: var(--#{$prefix}btn-falcon-background);
        border-color: var(--#{$prefix}btn-falcon-background);
        &:not(.disabled):not(:disabled) {
            color: var(--#{$prefix}btn-falcon-#{$color}-hover-color);
            box-shadow: var(--#{$prefix}btn-falcon-hover-box-shadow);
        }
    }
    &:active {
        box-shadow: none !important;
        color: var(--#{$prefix}btn-falcon-#{$color}-active-color);
    }

    .btn-check:checked + &,
    :not(.btn-check) + &:active,
    &:first-child:active,
    &.active,
    &.show > &.dropdown-toggle {
        color: var(--#{$prefix}btn-falcon-#{$color}-color);
        background-color: var(--#{$prefix}btn-falcon-#{$color}-active-background);
        // Remove CSS gradients if they're enabled
        // background-image: if($enable-gradients, none, null);
        border-color: var(--#{$prefix}btn-falcon-background);

        // &:focus {
        //   @if $enable-shadows {
        //     @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5));
        //   } @else {
        //     // Avoid using mixin so we can pass custom focus shadow properly
        //     box-shadow: 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5);
        //   }
        // }
    }

    &:disabled,
    &.disabled {
        color: var(--#{$prefix}btn-disabled-color);
        background-color: var(--#{$prefix}btn-falcon-background);
        // Remove CSS gradients if they're enabled
        background-image: if($enable-gradients, none, null);
        box-shadow: var(--#{$prefix}btn-falcon-box-shadow);
    }
}
