/*-----------------------------------------------
|   Landing banner style
-----------------------------------------------*/
.img-landing-banner,
.img-showcase-banner {
    border-top-left-radius: $border-radius-lg;
    border-top-right-radius: $border-radius-lg;
    overflow: hidden;
    transform: translateY(-3rem);
    margin-bottom: -28rem;
    transition: transform 0.4s ease;
    position: relative;
    box-shadow: $box-shadow-lg;
    display: block;
    @include media-breakpoint-only(xl) {
        margin-bottom: -18rem;
    }
    @include media-breakpoint-only(lg) {
        margin-bottom: -20rem;
    }
    &.img-showcase-banner {
        &:after {
            display: none;
        }
    }
    &:after {
        content: '';
        position: absolute;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
        left: 0;
        bottom: 0;
        width: 100%;
        height: 70%;
        transition: opacity 0.6s;
    }
    @include hover-focus {
        transform: translateY(-5rem);
        &:after {
            opacity: 0;
        }
    }
}

@include media-breakpoint-down(sm) {
    .landing-cta-img {
        width: 100%;
        height: auto;
    }
}

/*-----------------------------------------------
|  Back to top button   
-----------------------------------------------*/
.btn-back-to-top {
    background-color: $dark;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: $border-radius;
    left: 50%;
    top: -7.5rem;
    transform: translate3d(-50%, -50%, 0) rotate(-45deg);
    [dir='rtl'] & {
        transform: translate3d(-50%, -50%, 0) rotate(45deg);
    }
    height: 2.5rem;
    width: 2.5rem;
}

/*-----------------------------------------------
|   Special Card
-----------------------------------------------*/
.card-span {
    transition: all 0.4s ease;
    .card-span-img {
        position: absolute;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 5rem;
        height: 5rem;
        background-color: var(--#{$prefix}card-span-img-bg);
        box-shadow: var(--#{$prefix}card-span-img-box-shadow);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @include hover-focus {
        // transform: translateY(-0.2rem);
        box-shadow: var(--#{$prefix}card-span-img-hover-box-shadow);
    }
}
