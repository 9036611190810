em-emoji-picker {
    position: absolute;
    padding: 0;
    z-index: 1;
}

.chat-emoji-picker {
    em-emoji-picker {
        bottom: 2rem;
        left: auto;
        right: 2%;
    }
}
.dark {
    em-emoji-picker {
        --rgb-background: 11, 23, 39;
        border: 1px solid var(--#{$prefix}gray-200);
    }
}
