@mixin navbar-vertical-variant($variant, $bg-color, $bg-image, $shadow) {
    .navbar-collapse {
        @if $bg-color {
            background-color: var(--#{$prefix}navbar-vertical-#{$variant}-bg-color);
        }
        @if $bg-image {
            background-image: var(--#{$prefix}navbar-vertical-#{$variant}-bg-image);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            backface-visibility: hidden;
        }
        @if $shadow {
            box-shadow: $shadow !important;
        }
    }
    .navbar-nav .nav-item {
        .navbar-vertical-label-wrapper {
            min-height: 1.125rem;
        }
        .navbar-vertical-label {
            color: var(--#{$prefix}navbar-vertical-#{$variant}-label-color);
        }
        .nav-link {
            color: var(--#{$prefix}navbar-vertical-#{$variant}-link-color);
            &.dropdown-indicator:after {
                border-color: var(--#{$prefix}navbar-vertical-#{$variant}-link-color);
            }
            @include hover-focus {
                color: var(--#{$prefix}navbar-vertical-#{$variant}-link-hover-color);
                &.dropdown-indicator:after {
                    border-color: var(--#{$prefix}navbar-vertical-#{$variant}-link-hover-color);
                }
            }
            &.active {
                color: var(--#{$prefix}navbar-vertical-#{$variant}-link-active-color);
            }
            &.nav-link-disable {
                color: var(--#{$prefix}navbar-vertical-#{$variant}-link-disable-color) !important;
            }
        }
    }
    .navbar-vertical-divider {
        color: var(--#{$prefix}navbar-vertical-#{$variant}-hr-color);
    }
}

@mixin navbar-vertical-scrollbar($variant) {
    .windows {
        &.chrome {
            .navbar-vertical {
                @if ($variant) {
                    &.navbar-#{$variant} .scrollbar::-webkit-scrollbar-thumb {
                        background-color: var(--#{$prefix}navbar-vertical-#{$variant}-scrollbar-color);
                    }
                } @else {
                    .scrollbar::-webkit-scrollbar-thumb {
                        background-color: var(--#{$prefix}navbar-vertical-#{$variant}-scrollbar-color);
                    }
                }
            }
        }
        &.firefox {
            .navbar-vertical {
                @if ($variant) {
                    &.navbar-#{$variant} .scrollbar {
                        scrollbar-color: var(--#{$prefix}navbar-vertical-#{$variant}-scrollbar-color) transparent;
                    }
                } @else {
                    .scrollbar {
                        scrollbar-color: var(--#{$prefix}navbar-vertical-#{$variant}-scrollbar-color) transparent;
                    }
                }
            }
        }
    }
}
