/*-----------------------------------------------
|   Backgrounds
-----------------------------------------------*/
.bg-holder {
    position: absolute;
    width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    will-change: transform, opacity, filter;
    backface-visibility: hidden;
    background-repeat: no-repeat;
    z-index: 0;

    &.bg-right {
        left: auto;
        right: 0;
    }

    &.overlay:before {
        position: absolute;
        content: '';
        background: rgba(#000, 0.25);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    &.overlay-0:before {
        background: rgba(#000, 0.7);
    }
    &.overlay-1:before {
        background: rgba(#000, 0.55);
    }
    &.overlay-2:before {
        background: rgba(#000, 0.4);
    }

    .bg-video {
        position: absolute;
        display: block;
        z-index: -1;
        top: 0;
        left: 0;
        object-fit: cover;
        height: 100%;
        min-width: 100%;
    }

    .bg-youtube {
        position: absolute !important;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}

.bg-fixed {
    background-attachment: fixed;
}

.bg-glass {
    background-color: rgba($white, 0.9);
}

.bg-card {
    background-size: contain;
    background-position: right;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;

    [dir='rtl'] & {
        background-position: left;
        transform: scaleX(-1);
        border-top-left-radius: 0.375rem;
        border-bottom-left-radius: 0.375rem;
    }
}

/*-----------------------------------------------
|   Background gradient
-----------------------------------------------*/
.bg-line-chart-gradient {
    background-image: var(--#{$prefix}line-chart-gradient);
    background-position: center;
}
.bg-card-gradient {
    background-image: var(--#{$prefix}card-gradient);
    background-position: center;
}
.bg-progress-gradient {
    background-image: var(--#{$prefix}progress-gradient);
    background-position: center;
}
.bg-auth-circle-shape,
.bg-auth-circle-shape-2 {
    display: none;
    @include media-breakpoint-up(lg) {
        display: block;
    }
}
.bg-auth-circle-shape {
    position: absolute;
    right: -8.75rem;
    top: -5.125rem;
}
.bg-auth-circle-shape-2 {
    position: absolute;
    left: -6.25rem;
    bottom: -2.4375rem;
}
.bg-auth-card-shape {
    background-position: 0 100%;
    @include media-breakpoint-up(md) {
        background-position: 0 133%;
    }
}
@mixin shape($bg-color) {
    position: relative;
    overflow: hidden;
    background-color: $bg-color;
    &:after,
    &:before {
        position: absolute;
        content: '';
        border-radius: 50%;
    }
}

.bg-shape {
    @include shape(var(--#{$prefix}bg-shape-bg));
    &:after {
        background-image: var(--#{$prefix}bg-shape-bg-ltd);
    }
    &:before {
        background-image: var(--#{$prefix}bg-shape-bg-dtl);
    }
}

.bg-circle-shape {
    &:after {
        height: 15.625rem;
        width: 115%;
        left: 32%;
        top: -188%;
    }
    &:before {
        height: 332%;
        width: 45%;
        left: -9%;
        top: 0.5625rem;
    }
}
.modal-shape-header {
    &:before {
        height: 28.9375rem;
        width: 155%;
        right: 23%;
        top: -357%;
    }
    &:after {
        height: 289%;
        width: 45%;
        right: -10%;
        top: 2.5rem;
    }
}
.showcase-page-gradient {
    background: linear-gradient(357.7deg, #024787 -88.88%, rgba(0, 79, 197, 0) 27.93%);
}

.bg-transparent-50 {
    background-color: var(--#{$prefix}transparent-50);
}
